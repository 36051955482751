@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Khojki:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Khojki:wght@400;500;600;700&family=Secular+One&display=swap");
* {
  scroll-behavior: smooth;
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Secular One", sans-serif;
  background-image: url("./assets/background.gif");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: rgb(41, 41, 41);
}

::selection {
  color: rgb(0, 0, 0);
  background-color: rgb(69, 255, 181);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #fc598e;
  /*  */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(0, 187, 178);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(2, 117, 111);
}
